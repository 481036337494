import _ from 'lodash';
import { selectorKey } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';
import {
    CaseSettings,
    CaseSettingsState,
    SurgeonPreferences,
    SurgicalSpecInfo,
} from '@/stores/caseSettings/types';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import {
    HipSpecificationStore,
    HipSurgicalSpecificationDefaults,
} from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';

export const mapToState = (
    project: CaseRepresentation,
    surgicalSpecificationStore?: HipSpecificationStore
): Partial<CaseSettingsState> => {
    let selectedSurgeon = '';

    if (project.surgeon) {
        selectedSurgeon = LinkUtil.getUri(project.surgeon, LinkRelation.canonicalOrSelf) || '';
    }

    let surgicalSpecifications: SurgicalSpecInfo = {
        preferredSystem: '',
        stemTypes: [],
        legLengthChange: String(HipSurgicalSpecificationDefaults.TARGET.LEG_LENGTH_CHANGE),
        offsetChange: String(HipSurgicalSpecificationDefaults.TARGET.OFFSET_CHANGE),
        inclinationAngle: '',
        fittingMode: '',
        anteversionMode: '',
        anteversionAngle: '',
    };
    if (surgicalSpecificationStore?.isReady) {
        surgicalSpecifications = {
            preferredSystem: surgicalSpecificationStore.preferredSystem,
            stemTypes: _.sortBy((surgicalSpecificationStore.stemSelector || []).map(selectorKey)),
            legLengthChange: String(surgicalSpecificationStore.targetLegLengthChange),
            offsetChange: String(surgicalSpecificationStore.targetOffsetChange),
            inclinationAngle: String(surgicalSpecificationStore.abductionAngle),
            fittingMode: surgicalSpecificationStore.cupFitMethod,
            anteversionMode: surgicalSpecificationStore.anteversionMode,
            anteversionAngle: String(surgicalSpecificationStore.anteversionAngle),
        };
    }

    const mappedCaseSettings: CaseSettings = {
        caseInfo: {
            name: project.name || '',
            description: project.description || '',
            surgeryDate: project.surgery_date || '',
            procedure: project.procedure || '',
            implantSide: project.side || '',
        },
        patient: {
            name: {
                given: project.patient?.name?.given || '',
                middle: project.patient?.name?.middle || '',
                family: project.patient?.name?.family || '',
            },
            birth_date: project.patient?.birth_date ? project.patient?.birth_date : '',
            sex: project.patient?.sex || '',
        },
        selectedSurgeon: selectedSurgeon,
        surgicalSpecification: surgicalSpecifications,
    };

    return {
        settings: mappedCaseSettings,
        original: _.cloneDeep(mappedCaseSettings),
    };
};


export const mapSurgeonPreferencesToState = (surgeonPreferences: SurgeonPreferences):
    Partial<SurgicalSpecInfo> => {

    return {
        preferredSystem: surgeonPreferences.preferred_system,
        stemTypes: _.sortBy((surgeonPreferences.stem.selector || []).map(selectorKey)),
        inclinationAngle: String(surgeonPreferences.cup.abduction_angle),
        fittingMode: surgeonPreferences.cup.fit_method,
        anteversionMode: surgeonPreferences.cup.anteversion_mode,
        anteversionAngle: surgeonPreferences.cup.anteversion_angle ?
            String(surgeonPreferences.cup.anteversion_angle) : '',
    };
};
