import _ from 'lodash';
import { defineStore } from 'pinia';
import { AxiosResponse } from 'axios';
import {
    CaseSettingsState,
    sexOptions,
    sideOptions,
    SurgeonPreferences,
} from '@/stores/caseSettings/types';
import HipCupFitMethodOptions from '@/components/data/combobox/hip/HipCupFitMethodOptions';
import { LinkUtil } from 'semantic-link';
import {
    HipSpecificationStore,
    useHipSpecificationStore,
} from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
import { mapSurgeonPreferencesToState, mapToState } from '@/stores/caseSettings/mapToState';
import { getHipStemTypeTextOptions } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';
import LinkRelation from '@/lib/api/LinkRelation';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { inputFieldValidators, isValid } from '@/stores/caseSettings/validation';
import anylogger from 'anylogger';
import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { HipCupAnteversionMode } from '@/lib/api/representation/interfaces';
import { loadHipSurgeons } from '@/components/data/combobox/hip/HipSurgeonSelectorOptions';
import {
    getSurgeonPreferences,
    loadProject,
    patientSubmitRequest,
    projectSubmitRequest,
    unassignSurgeonSubmitRequest,
    surgicalSpecificationSubmitRequest,
} from '@/stores/caseSettings/requests';
import { HipUserStore, useHipUser } from '@/stores/hipUser/store';
import { initialState } from '@/stores/caseSettings/initialState';
import { canUserDoManualTemplating } from '@/lib/case/utils';
import { SpinopelvicStore, useSpinopelvicStore } from '@/stores/spinopelvic/store';
import { findUrl } from '@/stores/caseSettings/utils';
import PreferredSystemOptions from '@/components/data/combobox/hip/PreferredSystems';

export const SELECTED_SURGEON_ERROR = 'Selected surgeon does not have saved surgical preferences. ' +
    'Surgeon not available. Please select a different surgeon.';

// export log for testing purposes
export const log = anylogger('CaseSettingsStore');

export const useCaseSettings = defineStore('caseSettings', {
    state: () => {
        // cloneDeep will prevent retaining state by pinia magic.
        return _.cloneDeep(initialState);
    },
    getters: {
        options(state: CaseSettingsState) {
            return {
                sex: sexOptions,
                side: sideOptions,
                surgeons: state.availableSurgeons,
                stemTypes: state.availableStemTypes,
                cupFittingModes: HipCupFitMethodOptions,
                preferredSystems: PreferredSystemOptions,
            };
        },
        isValid(state: CaseSettingsState): boolean {
            return isValid(state.settings) && this.spinopelvicStore.validateState();
        },
        hasChanged(state: CaseSettingsState) {
            const sortedCaseSettings = _.cloneDeep(state.settings);
            sortedCaseSettings.surgicalSpecification.stemTypes = _.sortBy(
                sortedCaseSettings.surgicalSpecification.stemTypes);
            return !_.isEqual(state.original, sortedCaseSettings);
        },
        isManualAnteversionMode(state: CaseSettingsState): boolean {
            return state.settings.surgicalSpecification.anteversionMode === HipCupAnteversionMode.Manual;
        },
        isBeverlandMode(state: CaseSettingsState): boolean {
            return state.settings.surgicalSpecification.fittingMode === HipCupFitMethodEnum.Beverland;
        },
        hasActiveStudy(state: CaseSettingsState): boolean {
            return state.project?.activeStudy !== null && state.project?.activeStudy !== undefined;
        },
        hasSurgicalSpecification(state: CaseSettingsState): boolean {
            return state.project?.surgicalSpecification !== null && state.project?.surgicalSpecification !== undefined;
        },
        hipSpecificationStore(): HipSpecificationStore {
            return useHipSpecificationStore();
        },
        spinopelvicStore(): SpinopelvicStore {
            return useSpinopelvicStore();
        },
        hipUserStore(): HipUserStore {
            return useHipUser();
        },
        isOwner(): boolean {
            const projectOwnerUri = LinkUtil.getUri(this.project, LinkRelation.owner);
            return this.hipUserStore.myUserUri == projectOwnerUri;
        },
        canUnassignSurgeon(): boolean {
            // Determine whether the current user is allowed to remove surgeon from a project
            // Conditions: admins or sales rep who is also the owner of this project
            return this.hipUserStore.isAdmin ||
                this.hipUserStore.isOrgAdmin ||
                (this.hipUserStore.isSales && this.isOwner);
        },
        canUserDoManualTemplating(): boolean {
            return canUserDoManualTemplating(this.project?.activeStudy, this.project?.acidSurgicalTemplate);
        },
    },
    actions: {
        validate(field: string, error: string) {
            if (inputFieldValidators[field] === undefined) {
                throw new Error(`no validator for ${field}`);
            }

            if (!this.isDirty) {
                return '';
            }

            return inputFieldValidators[field](this.settings) ? '' : error;
        },
        validateSelectedSurgeon(): string {
            if (this.hasActiveStudy) {
                return '';
            }
            return this.validate('selectedSurgeon', 'Please select a surgeon') || this.selectedSurgeonError;
        },
        async onSelectedSurgeon(): Promise<void> {
            // Validate the selected surgeon is not empty and has surgical preferences, then update surgeon
            // preferences in surgical specification fields
            if (this.settings.selectedSurgeon) {
                try {
                    const surgeonPreferences = await getSurgeonPreferences(this.settings.selectedSurgeon);
                    this.validateSelectedSurgeonPreferences(surgeonPreferences);
                    this.updateSpecificationFromSurgeonPreferences(surgeonPreferences);
                } catch (err) {
                    log.warn('Failed to validate selected surgeon preferences: %o',  err);
                    this.hasSurgeonError = true;
                    this.selectedSurgeonError = SELECTED_SURGEON_ERROR;
                }
            }
        },
        validateSelectedSurgeonPreferences(surgeonPreferences: SurgeonPreferences): void {
            // The surgeon must have all their preferences/user settings configured to be eligible.
            // This checks that there is 'some value', not that the values are valid.
            this.hasSurgeonError = !(surgeonPreferences?.stem &&
                surgeonPreferences?.cup &&
                surgeonPreferences.stem.selector &&
                surgeonPreferences.stem.selector.length !== 0 &&
                surgeonPreferences.preferred_system &&
                surgeonPreferences.cup.anteversion_mode &&
                surgeonPreferences.cup.fit_method &&
                NumberUtil.isFiniteNumber(surgeonPreferences.cup.abduction_angle));

            this.selectedSurgeonError = this.hasSurgeonError ? SELECTED_SURGEON_ERROR : '';
        },
        updateSpecificationFromSurgeonPreferences(surgeonPreferences: SurgeonPreferences): void {
            if (!this.hasSurgeonError && !this.isLoading && !this.isSubmitting) {
                // Update the surgical preferences base on newly selected surgeon profile
                this.$patch({
                    settings: {
                        surgicalSpecification: mapSurgeonPreferencesToState(surgeonPreferences),
                    },
                });
            }
        },
        async load(apiUri: string) {
            log.info('Loading case settings store...');
            this.isSubmitting = false;
            this.isSubmitted = false;
            this.isDirty = false;
            this.hasError = false;
            this.hasSurgeonError = false;
            this.selectedSurgeonError = '';
            this.isLoading = true;
            this.isSuitable = true;

            this.availableSurgeons = [];

            this.hipSpecificationStore.$reset();
            this.hipUserStore.$reset();
            this.spinopelvicStore.$reset();

            await this.hipUserStore.load();
            await this.loadProject(apiUri);
            await this.spinopelvicStore.load(findUrl(this.project, LinkRelation.self));
            await this.loadAvailableSurgeons();
            this.availableStemTypes = await getHipStemTypeTextOptions(this.$http);

            this.$patch(mapToState(this.project, this.hipSpecificationStore));

            this.isLoading = false;
            log.info('Finished loading case settings store.');
        },
        async loadProject(apiUri: string): Promise<void> {
            log.info('Loading project data');
            try {
                this.project = await loadProject(this.$api, this.$apiOptions, apiUri);
                if (this.hasSurgicalSpecification) {
                    await this.hipSpecificationStore.init(this.project.surgicalSpecification);
                }
            } catch (err) {
                throw new Error(`Failed to load project data`);
            }
        },
        async loadAvailableSurgeons(): Promise<void> {
            const productUri = LinkUtil.getUri(this.project, LinkRelation.product);
            if (!productUri) {
                throw new Error('Failed to load surgeons list, missing product uri.');
            }

            this.availableSurgeons = await loadHipSurgeons(this.$api, this.$apiOptions, productUri);
        },
        async save(apiUri: string, topOfForm: Element): Promise<void> {
            this.isSubmitted = false;
            this.isSubmitting = true;

            if (!this.isValid || this.hasSurgeonError) {
                this.hasError = true;
                this.isDirty = true;
                this.isSubmitting = false;
                topOfForm.scrollIntoView({ behavior: 'smooth' });
                log.warn('There are invalid input fields. Save action is not performed.');
                return;
            }

            /*
            The following logic sends a series of 3 PUT requests to update all the case settings data.
            1. getProjectSubmitRequest() => Update Case Information and the selected surgeon for this case.
                It is important to send this request first because in some scenarios a new case can be created by a
                super admin or surgeon assistant where the default surgeon is not automatically selected by the backend
                service. This results in no surgical specification linked to this case, so by sending this request first
                we ensure that a surgical specification is created for subsequent requests.

             2. getPatientSubmitRequest() => Update the patient information. This request can only be sent if there is
                no active study (DICOM uploaded) associated with this case. Otherwise, API will return 409.

             3. getSurgicalSpecificationSubmitRequest() => Update the surgical specification on the case. The first try
                may fail if the case doesn't have a surgical specification associated with it (no surgeon selected).
                Then we would try again once the project submit request succeeds and a new surgical specification is
                created and available for updating.

             TODO: This seems to be overly complicated and unnecessary. We should look at updating the API with a
                single endpoint that could update the case data without the client needing to have this complex logic.
             */
            try {
                let requests: Promise<AxiosResponse>[] = [
                    projectSubmitRequest(this),
                ];

                if (!this.hasActiveStudy) {
                    // Prevent updating patient info when the case already have an active study
                    requests.push(patientSubmitRequest(this));
                }

                if (!this.hasSurgicalSpecification) {
                    // A surgical specification for this project doesn't yet exist.
                    // Update project first so the backend service can create one, then update with new surgical spec data
                    await Promise.all(requests);

                    // Reload the project surgical specification data, we should now have a surgical specification resource
                    this.project = await loadProject(this.$api, this.$apiOptions, apiUri);

                    // Reset the requests queue, so we don't send the same PUT requests again.
                    requests = [];
                }

                // Update surgical specification
                requests.push(surgicalSpecificationSubmitRequest(this));
                await Promise.all(requests);

                await this.spinopelvicStore.save();

                this.hasError = false;
                this.isDirty = false;
                this.isSuitable = true;

                this.$patch({
                    original: _.cloneDeep(this.settings),
                });
                // We need to sort the order, otherwise the store thinks there is a change
                this.original.surgicalSpecification.stemTypes = _.sortBy(this.original.surgicalSpecification.stemTypes);
            } catch (err) {
                log.error('Error: Failed to update some resources: %o', err);
                this.hasError = true;
                topOfForm.scrollIntoView({ behavior: 'smooth' });
            } finally {
                this.isSubmitted = true;
                this.isSubmitting = false;
            }
        },
        async unassignSurgeon(): Promise<void> {
            // Send a request to un-assign surgeon from the case
            this.isSubmitted = false;
            this.isSubmitting = true;

            try {
                await unassignSurgeonSubmitRequest(this);

                // update surgeon changes
                this.settings.selectedSurgeon = '';
                this.original.selectedSurgeon = '';

                this.hasError = false;
                this.isSuitable = false;
            } catch (err) {
                log.error('Error: Failed to remove surgeon from project: %o', err);
                this.hasError = true;
            } finally {
                this.isSubmitted = true;
                this.isSubmitting = false;
            }
        },
    },
});

export type CaseSettingsStore = ReturnType<typeof useCaseSettings>;
