import { PreferredSystemEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import {
    SelectPreferredSystemOption,
} from '@/components/data/combobox/types/component-selectors/types';

const PreferredSystemOptions: SelectPreferredSystemOption[] = [
    {
        value: PreferredSystemEnum.AvenirComplete,
        text: 'Avenir Complete',
    },
    {
        value: PreferredSystemEnum.TaperlocComplete,
        text: 'Taperloc Complete',
    },
];

export default PreferredSystemOptions;
