import axios from 'axios';
import { defineStore } from 'pinia';
import { Link } from 'semantic-link';
import _ from 'lodash';
import { getHipStemTypeTextOptions } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';
import HipCupAnteversionModes from '@/components/data/combobox/hip/HipCupAnteversionModes';
import HipAlignmentModes from '@/components/data/combobox/hip/HipAlignmentModes';
import HipCupFitMethodOptions from '@/components/data/combobox/hip/HipCupFitMethodOptions';
import UserResource from '@/lib/api/resource/user/UserResource';
import ProductPreferencesResource from '@/lib/api/resource/user/ProductPreferencesResource';
import { HipProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import { requestBodyFromState, stateFromResponse } from '@/stores/preferences/mapping';
import { Preferences, State } from '@/stores/preferences/types';
import { isValid, validators } from '@/stores/preferences/validation';
import PreferredSystemOptions from '@/components/data/combobox/hip/PreferredSystems';

export const emptyPreferences: Preferences = {
    stemTypes: [],
    cupInclinationAngle: '',
    cupFittingMode: '',
    cupAnteversionMode: '',
    cupAnteversionAngle: '',
    alignmentMode: '',
    preferredSystem: '',
};

const initialState: State = {
    isLoading: true,
    isSubmitting: false,
    isSubmitted: false,
    isDirty: false,
    hasError: false,
    preferences: { ...emptyPreferences },
    original: { ...emptyPreferences },
    availableStemTypes: [],
    links: [],
};

export const usePreferences = defineStore('preferences', {
    state: () => {
        return { ...initialState };
    },
    getters: {
        options(state: State) {
            return {
                stemTypes: state.availableStemTypes,
                fittingModes: HipCupFitMethodOptions,
                anteversionModes: HipCupAnteversionModes,
                alignmentModes: HipAlignmentModes,
                preferredSystems: PreferredSystemOptions,
            };
        },
        isValid(state: State) {
            return isValid(state.preferences);
        },
    },
    actions: {
        hasChanged() {
            // stem types must be sorted as removing and adding stem types
            // may produce a different order from what the api returned.
            const sortedPreferences = {
                ...this.preferences,
                stemTypes: _.sortBy(this.preferences.stemTypes),
            };
            return !_.isEqual(this.original, sortedPreferences);
        },
        validate(field: string, error: string) {
            if (validators[field] === undefined) {
                throw new Error(`no validator for ${field}`);
            }
            if (!this.isDirty) {
                return '';
            }

            return validators[field](this.preferences) ? '' : error;
        },
        async load() {
            this.isSubmitted = false;
            this.isDirty = false;
            this.hasError = false;
            this.isLoading = true;

            const me = await UserResource.getMeUser(this.$api, this.$apiOptions);
            if (!me) {
                throw new Error('could not load user');
            }

            const representation = await ProductPreferencesResource
                .getSurgeonPreferencesSingleton<HipProductPreferencesRepresentation>(me, Product.Hip, this.$apiOptions);

            this.$patch(stateFromResponse(representation));

            this.availableStemTypes = await getHipStemTypeTextOptions(this.$http);

            this.isLoading = false;
        },
        async save(topOfForm: Element) {
            this.isSubmitted = false;
            this.isSubmitting = true;

            const submitUrl = this.links.find((link: Link) => link.rel === 'self');
            if (!submitUrl) {
                throw new Error('self link missing');
            }

            if (!this.isValid) {
                this.hasError = true;
                this.isDirty = true;
                this.isSubmitting = false;
                topOfForm.scrollIntoView({ behavior: 'smooth' });
                return;
            }

            await axios
                .put(submitUrl.href, requestBodyFromState(this))
                .then(() => {
                    this.hasError = false;
                    this.isDirty = false;
                    this.$patch({
                        original: {
                            ...this.preferences,
                            stemTypes: _.sortBy(this.preferences.stemTypes),
                        },
                    });
                })
                .catch(() => {
                    this.hasError = true;
                })
                .finally(() => {
                    topOfForm.scrollIntoView({ behavior: 'smooth' });
                    this.isSubmitting = false;
                    this.isSubmitted = true;
                });
        },
    },
});
