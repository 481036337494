/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
    AlignmentModeEnum,
    AlignmentModeType,
    ComponentSelectorRepresentation,
    HipCupFitMethodEnum,
    HipCupFitMethodType,
    HipSurgicalSpecificationRepresentation,
    PreferredSystemEnum,
    PreferredSystemType,
} from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { HipProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import { HipCupAnteversionMode, HipCupAnteversionModeType } from '@/lib/api/representation/interfaces';
import { defineStore } from 'pinia';
import assert from 'assert';
import anylogger from 'anylogger';
import SurgicalSpecificationModelFactory
    from '@/lib/api/resource/case/surgical-specification/SurgicalSpecificationModelFactory';

const log = anylogger('hip-surgical-specification-store');

/**
 * Hip surgical spec defaults
 */
const DEFAULTS = {
    TARGET: {
        LEG_LENGTH_CHANGE: 0.0,
        OFFSET_CHANGE: 0.0,
    },
    CUP: {
        INCLINATION_ANGLE: 40.0,
        ANTEVERSION_ANGLE_MANUAL: 20.0,
        ANTEVERSION_ANGLE_AUTO: AlignmentModeEnum.None,
        ANTEVERSION_MODE: HipCupAnteversionMode.Auto,
        FIT_METHOD: HipCupFitMethodEnum.Acid,
    },
    PREFERRED_SYSTEM: PreferredSystemEnum.TaperlocComplete,
};

export const HipSurgicalSpecificationDefaults = DEFAULTS;

export type HipSpecificationsState = {
    isLoading: boolean;
    isLoaded: boolean;

    specification: HipSurgicalSpecificationRepresentation | null;
    preferences: HipProductPreferencesRepresentation | null;
}

const initialState: HipSpecificationsState = {
    isLoading: true,
    isLoaded: false,
    specification: null,
    preferences: null,
};

export const useHipSpecificationStore = defineStore('hipSpecification', {
    state: () => {
        return { ...initialState };
    },
    getters: {
        isReady(): boolean {
            return this.isLoaded;
        },
        /**
         * Get the leg length change from the surgical specifications.
         * Otherwise, return a default value.
         * Note that the surgical preferences do not include target leg length change.
         */
        targetLegLengthChange(state: HipSpecificationsState): number {
            return state.specification!.target.leg_length_change ?? DEFAULTS.TARGET.LEG_LENGTH_CHANGE;
        },
        /**
         * Get the target leg offset change from the surgical specifications.
         * Otherwise, return a default value.
         * Note that the surgical preferences do not include target leg offset change.
         */
        targetOffsetChange(state: HipSpecificationsState): number {
            return state.specification!.target.offset_change ?? DEFAULTS.TARGET.OFFSET_CHANGE;
        },
        anteversionAngle(state: HipSpecificationsState): number {
            return state.specification!.cup.anteversion_angle ?? this.preferencesAnteversionAngle;
        },
        preferencesAnteversionAngle(state: HipSpecificationsState): number {
            return state.preferences!.cup.anteversion_angle ?? DEFAULTS.CUP.ANTEVERSION_ANGLE_MANUAL;
        },
        anteversionMode(state: HipSpecificationsState): HipCupAnteversionModeType {
            return state.specification!.cup.anteversion_mode ??
                state.preferences!.cup.anteversion_mode ??
                DEFAULTS.CUP.ANTEVERSION_MODE;
        },
        abductionAngle(state: HipSpecificationsState): number {
            return state.specification!.cup.abduction_angle ?? this.preferencesAbductionAngle;
        },
        preferencesAbductionAngle(state: HipSpecificationsState): number {
            // eslint-disable-next-line camelcase
            return state.preferences!.cup.abduction_angle ?? DEFAULTS.CUP.INCLINATION_ANGLE;
        },
        cupFitMethod(state: HipSpecificationsState): HipCupFitMethodType {
            return state.specification!.cup.fit_method ?? this.preferencesCupFitMethod;
        },
        preferencesCupFitMethod(state: HipSpecificationsState): HipCupFitMethodType {
            // eslint-disable-next-line camelcase
            return state.preferences!.cup.fit_method ?? DEFAULTS.CUP.FIT_METHOD;
        },
        stemSelector(state: HipSpecificationsState): ComponentSelectorRepresentation[] | undefined {
            return state.specification!.stem.selector ?? this.preferencesStemSelector;
        },
        preferencesStemSelector(state: HipSpecificationsState): ComponentSelectorRepresentation[] | undefined {
            return state.preferences!.stem.selector;
        },
        /**
         * Get the alignment mode on the surgical specification
         * Given it can be null on the surgical specification, it can get it from the surgeon preferences
         */
        alignmentMode(): AlignmentModeType | never {
            const specification = this.specification!;

            // eslint-disable-next-line camelcase
            if (specification?.alignment_mode) {
                return specification.alignment_mode;
            } else {
                const surgeonPreferences = this.preferences!;
                // eslint-disable-next-line camelcase
                if (surgeonPreferences?.align_mode) {
                    return surgeonPreferences.align_mode;
                }
            }

            throw new Error('Alignment mode not set on the specification neither on surgeon preferences');
        },
        preferredSystem(): PreferredSystemType {
            if (this.specification?.preferred_system) {
                return this.specification.preferred_system;
            } else {
                return this.preferences?.preferred_system ?? DEFAULTS.PREFERRED_SYSTEM;
            }
        },
    },
    actions: {
        async init(specification: HipSurgicalSpecificationRepresentation): Promise<void> {
            log.info('Loading surgical specification store...');

            this.resetTransientState();

            try {
                this.isLoading = true;
                assert.ok(!!specification, 'specification must be loaded');
                const preferences = await SurgicalSpecificationModelFactory.getPreferences<HipProductPreferencesRepresentation>(
                    specification, this.$apiOptions);
                assert.ok(!!preferences, 'preferences must be loaded');

                this.specification = specification;
                this.preferences = preferences;
                this.isLoading = false;
            } finally {
                this.isLoading = false;
                this.isLoaded = true;
            }
        },
        resetTransientState(): void {
            this.isLoading = false;
            this.isLoaded = false;
        },
    },
});

export type HipSpecificationStore = ReturnType<typeof useHipSpecificationStore>;
