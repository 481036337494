import { CollectionRepresentation, LinkedRepresentation } from 'semantic-link';
import {
    HipProductPreferencesRepresentation,
    ProductPreferencesRepresentation,
} from '@/lib/api/representation/ProductPreferencesRepresentation';
import { HipCupAnteversionModeType } from '@/lib/api/representation/interfaces';
import { Representation } from '@/lib/semanticNetworkMigrationUtils';
import { PatientSex, PatientSexType } from '@/lib/api/representation/PatientRepresentation';

/**
 * Pelvis alignment system
 *
 * IMPORTANT: These values match the values that compute uses to check the alignment type
 */
export enum AlignmentModeEnum {

    /**
     * No alignment from the base imaging (CT scan) is performed.
     */
    None = 'none',

    /**
     * Functional Pelvic Plane (FPP)
     */
    FPP = 'functional',

    /**
     * Anterior Pelvic Plane (APP)
     */
    APP = 'APP',
}

export type AlignmentModeType = AlignmentModeEnum.None | AlignmentModeEnum.FPP | AlignmentModeEnum.APP;

export enum HipCupFitMethodEnum {
    Acid = 'acid',
    Beverland = 'beverland',
    Fossa = 'fossa',
}

export type HipCupFitMethodType = HipCupFitMethodEnum.Acid | HipCupFitMethodEnum.Beverland | HipCupFitMethodEnum.Fossa;

export enum PreferredSystemEnum {
    TaperlocComplete = 'taperloc-complete',
    AvenirComplete = 'avenir-complete',
}

export type PreferredSystemType = PreferredSystemEnum.TaperlocComplete | PreferredSystemEnum.AvenirComplete;

/**
 * Position of the acetabular cup relative to its coordinate system originating
 * from the acetabular centre along the 3 body axes
 */
export interface HipCupPositionRepresentation {
    /** position on the anterior to posterior axis */
    ap: number;

    /** position on the superior to inferior axis */
    si: number;

    /** position on the medial to lateral axis */
    ml: number;
}

/**
 * A set of components are selected with an array of these representations. Each component will
 * have a system/type/size field that is used to filter which should be used.
 *
 * Rules:
 * <ul>
 *       <li>an empty array or null/undefined array is deemed invalid (not defined)</li>
 *       <li>an array with no 'system/type/size' matches all items</li>
 *       <li>the **unique** set of all components that matches the selectors must be used</li>
 *       <li>there is no exclusion rule (only an inclusion rule)</li>
 * </ul>
 */
export interface ComponentSelectorRepresentation extends Representation {
    /**
     * The optional name of the component system. e.g. 'taperloc-complete'
     */
    system?: string;

    /**
     * The optional component type.
     *
     * Note: Some components use an extended form of the type string with an array style
     * string notation. i.e. "type[sub-type]"
     */
    type?: string;

    /**
     * A optional size which may be a t-shirt size or an actual size or a string with units.
     */
    size?: string | number;
}

/**
 * Base class for surgical specifications.
 *
 * Note: this is a type as the interface is empty.
 */
export interface SurgicalSpecificationRepresentation<TP extends ProductPreferencesRepresentation> extends LinkedRepresentation {
    /**
     * When the surgical specification was created.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    created?: string;
    /**
     * When the surgical specification was last modified.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    updated?: string;

    /**
     * Materialise rel === 'preferences'
     *
     * The surgeon preferences used when creating the surgical specification
     */
    preferences?: TP;

    /**
     * Client side artifact of the 'history' link. This is the list of all records.
     */
    history: CollectionRepresentation<SurgicalSpecificationRepresentation<TP>>;
}

export type SurgicalSpecificationRepresentationType = SurgicalSpecificationRepresentation<ProductPreferencesRepresentation>

/**
 *
 * This forms part of {@link HipSurgicalSpecificationRepresentation}.
 */
export interface HipSurgicalSpecificationStemRepresentation {
    /**
     * A set of filters used to select which stems should be used during templating.
     */
    selector?: ComponentSelectorRepresentation[];
}

/**
 *
 * This forms part of {@link HipSurgicalSpecificationRepresentation}.
 */
export interface HipSurgicalSpecificationCupRepresentation {
    fit_method?: HipCupFitMethodType;
    abduction_angle?: number;
    anteversion_mode?: HipCupAnteversionModeType;
    anteversion_angle?: number;
}

/**
 * Attribute for s surgical specification around target specification for the templating process.
 *
 * This forms part of {@link HipSurgicalSpecificationRepresentation}.
 */
export interface HipSurgicalSpecificationTargetDesignRepresentation {
    /**
     * A design parameter used by the automated templating process to optimise towards a
     * specific change in leg length. This will influence which components are selected.
     */
    leg_length_change?: number;
    /**
     * TODO document
     * This used to be the 'medialisation'
     */
    offset_change?: number;
}

/**
 *  The surgical specifications for a hip project.
 *
 *  The surgical specification will come into existence once a surgeon has been assigned to the
 *  project and their preferences have been associated with the project. It is possible to create
 *  an empty record as soon as project is created, however this means data may be overridden when
 *  the surgeon is assigned.
 *
 *  Note: this replaces the old (flat) {@link HipSurgicalSpecificationsRepresentation}.
 */
export interface HipSurgicalSpecificationRepresentation
    extends SurgicalSpecificationRepresentation<HipProductPreferencesRepresentation> {
    stem: HipSurgicalSpecificationStemRepresentation;
    cup: HipSurgicalSpecificationCupRepresentation;
    target: HipSurgicalSpecificationTargetDesignRepresentation;
    alignment_mode?: AlignmentModeType;
    preferred_system: PreferredSystemType;
}

/**
 * Guard to determinate if x is a {@link HipSurgicalTemplateRepresentation}
 */
export function isHipSurgicalSpecificationsRepresentation(x: unknown): x is HipSurgicalSpecificationRepresentation {
    const asSpecification = x as HipSurgicalSpecificationRepresentation;
    return asSpecification &&
        'stem' in asSpecification &&
        'cup' in asSpecification &&
        'target' in asSpecification &&
        'alignment_mode' in asSpecification;
}

/**
 * Patient sex select option (in a dropdown list)
 */
export interface SelectSexOption {
    text: string;
    value?: PatientSexType;
}

/**
 * The current list of sex options (there aren't many options)
 */
export const PATIENT_SEX_OPTIONS: SelectSexOption[] = [
    {
        value: PatientSex.Male,
        text: 'Male',
    },
    {
        value: PatientSex.Female,
        text: 'Female',
    },
];

export interface SurgicalSpecificationCreateData {
    placeholder?: number;
}

export interface HipSurgicalSpecificationCreateData extends SurgicalSpecificationCreateData {
    stem: HipSurgicalSpecificationStemRepresentation;
    cup: HipSurgicalSpecificationCupRepresentation;
    target: HipSurgicalSpecificationTargetDesignRepresentation;
    alignment_mode?: AlignmentModeType;
}
export type AnySurgicalSpecificationCreateData = HipSurgicalSpecificationCreateData;
