import { CaseSettings, CaseSettingsState } from '@/stores/caseSettings/types';
import _ from 'lodash';

export const emptyCaseSettings: CaseSettings = {
    caseInfo: {
        name: '',
        description: '',
        surgeryDate: '',
        procedure: '',
        implantSide: '',
    },
    patient: {
        name: {
            given: '',
            middle: '',
            family: '',
        },
        birth_date: '',
        sex: '',
    },
    selectedSurgeon: '',
    surgicalSpecification: {
        preferredSystem: '',
        stemTypes: [],
        legLengthChange: '',
        offsetChange: '',
        inclinationAngle: '',
        fittingMode: '',
        anteversionMode: '',
        anteversionAngle: '',
    },
};

export const initialState: CaseSettingsState = {
    isLoading: true,
    isSubmitting: false,
    isSubmitted: false,
    isSuitable: true,
    isDirty: false,
    hasError: false,
    hasSurgeonError: false,
    project: null,
    selectedSurgeonError: '',
    availableSurgeons: [],
    availableStemTypes: [],
    settings: emptyCaseSettings,
    original: _.cloneDeep(emptyCaseSettings),
};
