import { Preferences } from '@/stores/preferences/types';
import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { HipCupAnteversionMode } from '@/lib/api/representation/interfaces';
import { isPositiveInteger, isNotEmpty } from '@/stores/utils/validation';
import { STEMTYPE_KEY_SEPARATOR } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';

export const validators: { [key: string]: (p: Preferences) => boolean; } = {
    stemTypes: ({stemTypes, preferredSystem}) =>
        stemTypes !== undefined && stemTypes?.filter((stemType) =>
            stemType.split(STEMTYPE_KEY_SEPARATOR)[0] === preferredSystem,
        ).length > 0,

    cupInclinationAngle: ({cupInclinationAngle}) =>
        isNotEmpty(cupInclinationAngle) &&
        isPositiveInteger(cupInclinationAngle),

    cupFittingMode: ({cupFittingMode}) =>
        isNotEmpty(cupFittingMode),

    cupAnteversionMode: ({cupAnteversionMode, cupFittingMode}) =>
        cupAnteversionMode !== undefined && (
            cupFittingMode === HipCupFitMethodEnum.Beverland || cupAnteversionMode.length > 0
        ),

    cupAnteversionAngle: ({cupAnteversionAngle, cupFittingMode, cupAnteversionMode}) =>
        cupAnteversionMode !== undefined && (
            cupFittingMode === HipCupFitMethodEnum.Beverland || cupAnteversionMode === HipCupAnteversionMode.Auto || (
                cupAnteversionMode === HipCupAnteversionMode.Manual &&
                isPositiveInteger(cupAnteversionAngle)
            )
        ),

    alignmentMode: ({alignmentMode, cupFittingMode}) =>
        alignmentMode !== undefined && (
            cupFittingMode === HipCupFitMethodEnum.Beverland || alignmentMode.length > 0
        ),
};

export const isValid = (preferences: Preferences): boolean =>
    Object
        .values(validators)
        .map(validator => validator(preferences))
        .every(result => result);
