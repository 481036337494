// option separator type
import {
    AlignmentModeType,
    ComponentSelectorRepresentation,
    HipCupFitMethodType,
    PreferredSystemType,
} from '@/lib/api/representation/SurgicalSpecificationRepresentation';

/**
 * Case alignment mode select option (in a dropdown list)
 */
export interface SelectAlignmentOption {
    text: string;
    value: AlignmentModeType;
    disabled?: boolean;
}

/**
 * Cup fitting method select option (in a dropdown list)
 */
export interface SelectCupFitMethodOption {
    text: string;
    value: HipCupFitMethodType;
}

/**
 * Preferred Stem Systems (in a dropdown list)
 */
export interface SelectPreferredSystemOption {
    text: string;
    value: PreferredSystemType;
}

// option divider type
export interface ComponentSelectorDividerItem {
    divider: boolean;
}

// option header type
export interface ComponentSelectorHeaderItem {
    header: string;
}

// option value/text
export interface ComponentSelectorValueTextOption {
    value: ComponentSelectorRepresentation;
    text: string;
}

export type ComponentSelectorOption =
    ComponentSelectorHeaderItem |
    ComponentSelectorDividerItem |
    ComponentSelectorValueTextOption;

export type ComponentSelectorOptions = ComponentSelectorOption[];

/**
 * A guard to detect whether the object is a ComponentSelectorValueTextOption (not a header, not a divider)
 *
 * @param object
 * @returns whether the object is an instance on the interface
 */
export function isValueTextOption(object: any): object is ComponentSelectorValueTextOption {
    return object.value !== undefined && object.text !== undefined;
}
